import React from 'react';


export default function Footer() {
  return (
    <footer id="footer" class="footer">
      <div class="container">
        <div class="copyright">
          &copy; Copyright <strong><span>Webbylon</span></strong>. All Rights Reserved
        </div>
        <div class="credits">
          Designed by <a href="https://www.facebook.com/rkramarev">Kramarus</a>
        </div>
      </div>
    </footer>
  )
}
